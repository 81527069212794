import { Injectable, inject } from '@angular/core';
import { User, UserProfileRole } from '@app/core/models/user-profile.model';
import { EmitMessage } from '@app/shared/decorators/message-emitter.decorator';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { arrayToMap } from '@shared/services/utils-helper.service';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs';
import { UserEndpointService } from '../services/user.endpoint.service';
import {
  CreateUser,
  DeleteUser,
  Disable2FA,
  Enable2FA,
  FetchRoles,
  FetchUsers,
  GetUser,
  UpdateUser,
  UsersLoadedSuccessfully,
} from './user.actions';

export interface UserStateModel {
  users: { [id: string]: User };
  roles: { [id: string]: UserProfileRole };
  isUsersLoaded: boolean;
  usersTimestamp: number;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    users: null,
    roles: null,
    isUsersLoaded: false,
    usersTimestamp: null
  },
})
@Injectable()
export class UserState {
  private userEndPointService = inject(UserEndpointService);

  static selectUser(id: string) {
    return createSelector([UserState], (state: UserStateModel): User => {
      return state?.users && (state.users[id] as User);
    });
  }

  static filterUserByTenant(tenantId: string) {
    return createSelector([UserState], (state: UserStateModel): User[] => {
      return state?.users && Object.values(state.users).filter((user: User) => user.owner == tenantId);
    });
  }

  @Selector()
  static roles(state: UserStateModel): { [id: string]: UserProfileRole } {
    return state?.roles;
  }

  @Selector([UserState.roles])
  static roleList(roles: { [id: string]: UserProfileRole }): UserProfileRole[] {
    return !!roles && Object.values(roles);
  }

  @Selector()
  static users(state: UserStateModel): { [id: string]: User } {
    return state?.users;
  }

  @Selector([UserState.users])
  static userList(users: { [id: string]: User }): User[] {
    return !!users && Object.values(users);
  }

  @Selector()
  static userLoaded(state: UserStateModel): boolean {
    return state?.isUsersLoaded;
  }

  @Action(FetchUsers)
  fetchUsers(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    return this.userEndPointService.getUsers().pipe(
          tap((users: User[]) => {
            ctx.patchState({
              users: arrayToMap(users),
            });
          }),
          switchMap((_) => ctx.dispatch(new UsersLoadedSuccessfully())),
          catchError((err) => {
            throw err;
          }),
        );
  }

  @Action(UsersLoadedSuccessfully)
  usersLodedSuccessfully(ctx: StateContext<UserStateModel>) {
    ctx.patchState({
      isUsersLoaded: true,
    });
  }

  @Action(FetchRoles)
  fetchRoles(ctx: StateContext<UserStateModel>, { force = false }) {
    const state = ctx.getState();
    return !force && state?.roles && Object.values(state.roles).length
      ? state.roles
      : this.userEndPointService.getRoles().pipe(
          tap((roles: UserProfileRole[]) => {
            ctx.patchState({
              roles: arrayToMap(roles),
            });
          }),
          catchError((err) => {
            throw err;
          }),
        );
  }

  @EmitMessage({ afterAction: { entity: 'user.user', action: 'actionMessage.updatedSuccess' } })
  @Action(UpdateUser)
  updateUser(ctx: StateContext<UserStateModel>, { payload }: UpdateUser) {
    const { id } = payload;
    const state = ctx.getState();
    const existingUser = structuredClone(state.users[id]);

    return this.userEndPointService.updateUser(payload).pipe(
      tap((user: User) => {
        this.setUsers(ctx, user, user.id);
      }),
      catchError((err) => {
        ctx.setState(state);
        throw err;
      }),
      map((_) => (existingUser?.roles?.join() !== payload?.roles?.join() ? ctx.dispatch(new FetchRoles(true)) : EMPTY)),
    );
  }

  @Action(GetUser)
  getUser(ctx: StateContext<UserStateModel>, { id }: GetUser) {
    const state = ctx.getState();
    if (!state?.users || !state?.users[id]) {
      return this.userEndPointService.getUser(id).pipe(
        tap((user: User) => {
          this.setUsers(ctx, user, user.id);
        }),
        catchError((err) => {
          throw err;
        }),
      );
    }
  }

  @EmitMessage({ afterAction: { entity: 'user.user', action: 'actionMessage.createdSuccess' } })
  @Action(CreateUser)
  createUser(ctx: StateContext<UserStateModel>, { payload }: CreateUser) {
    return this.userEndPointService.createUser(payload).pipe(
      tap((user: User) => {
        this.setUsers(ctx, user, user.id);
      }),
      map(() => ctx.dispatch(new FetchRoles(true))),
    );
  }

  @EmitMessage({ afterAction: { entity: 'user.user', action: 'actionMessage.deletedSuccess' } })
  @Action(DeleteUser)
  deleteUser(ctx: StateContext<UserStateModel>, { id }: DeleteUser) {
    const state = ctx.getState();
    return this.userEndPointService.deleteUser(id).pipe(
      tap((_) => {
        this.setUsers(ctx, { ...state.users[id], isActive: false }, id);
      }),
      catchError((err) => {
        throw err;
      }),
    );
  }

  // @EmitMessage({ afterAction: { entity: 'user.user', action: 'actionMessage.deletedSuccess' } })
  @Action(Enable2FA)
  enable2FA({ getState, patchState }, { id }: Enable2FA) {
    const state = getState();
    return this.userEndPointService.enable2FA(id).pipe(
      tap((_) => {
        patchState({ users: { ...state.users, [id]: { ...state.users[id], mfaEnabled: true } } });
      }),
    );
  }

  // @EmitMessage({ afterAction: { entity: 'user.user', action: 'actionMessage.deletedSuccess' } })
  @Action(Disable2FA)
  disable2FA({ patchState, getState }, { id }: Disable2FA) {
    const state = getState();
    return this.userEndPointService.disable2FA(id).pipe(
      tap((_) => {
        patchState({ users: { ...state.users, [id]: { ...state.users[id], mfaEnabled: false } } });
      }),
    );
  }

  private setUsers(ctx: StateContext<UserStateModel>, user: User, id: string) {
    const state = ctx.getState();
    ctx.patchState({
      users: state.users?.[id]
        ? { ...state.users, [id]: { ...state.users[id], ...user } }
        : { ...state.users, [id]: user },
    });
  }
}
