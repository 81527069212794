import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { User, UserProfileRole } from '@app/core/models/user-profile.model';
import { USER_MANAGEMENT_URL, VERIFY_EMAIL } from '@shared/config/endpoints';
import { Observable, catchError, debounceTime, distinctUntilChanged, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserEndpointService {
  private httpClient = inject(HttpClient);

  public getUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${USER_MANAGEMENT_URL}users/`);
  }

  public getUser(id: string): Observable<User> {
    return this.httpClient.get<User>(`${USER_MANAGEMENT_URL}users/${id}/`);
  }

  public updateUser(payload: User): Observable<User> {
    const { id } = payload;
    return this.httpClient.put<User>(`${USER_MANAGEMENT_URL}users/${id}/`, { ...payload });
  }

  public createUser(payload: User): Observable<any> {
    return this.httpClient.post<User>(`${USER_MANAGEMENT_URL}users/`, { ...payload });
  }

  public deleteUser(id: string): Observable<any> {
    return this.httpClient.delete<any>(`${USER_MANAGEMENT_URL}users/${id}/`);
  }

  public getRoles(): Observable<UserProfileRole[]> {
    return this.httpClient.get<UserProfileRole[]>(`${USER_MANAGEMENT_URL}business-roles/`);
  }

  public sendPasswordEmail(id: string): Observable<any> {
    return this.httpClient.put<any>(`${USER_MANAGEMENT_URL}users/${id}/send-reset-link/`, {});
  }

  public uniqueEmailValidator(initialEmail: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const newEmailValue = control?.value?.trim();
      return !!initialEmail && initialEmail == newEmailValue
        ? of(null)
        : this.httpClient.get<any>(`${VERIFY_EMAIL}?email=${newEmailValue}`).pipe(
            distinctUntilChanged(),
            debounceTime(500),
            map((data: any) => (!data.valid ? { requireUnique: true } : null)),
            catchError((_) => null),
          );
    };
  }

  public disable2FA(id: string): Observable<any> {
    return this.httpClient.put<any>(`${USER_MANAGEMENT_URL}users/${id}/disable-mfa/`, {});
  }

  public enable2FA(id: string): Observable<any> {
    return this.httpClient.put<any>(`${USER_MANAGEMENT_URL}users/${id}/enable-mfa/`, {});
  }
}
